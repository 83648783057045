import InfoIcon from "@mui/icons-material/Info";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  Card,
  CardContent,
  IconButton,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import styles from "./Calculator.module.css";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";
import { Link } from "react-router-dom";
import { MoveRight } from "lucide-react";

ChartJS.register(ArcElement, ChartTooltip, Legend);

const theme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: "#000000",
          width: 24,
          height: 24,
          border: "4px solid white",
          boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
        },
        track: {
          color: "#000000",
          height: 8,
          borderRadius: 4,
        },
        rail: {
          color: "#ccc",
          height: 8,
          borderRadius: 4,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "16px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: "'Roboto', sans-serif",
          fontWeight: 700,
        },
        subtitle1: {
          fontFamily: "'Roboto', sans-serif",
          fontWeight: 500,
        },
      },
    },
  },
});

function Calculator({ onLoad }) {
  const { t } = useTranslation();

  // Estados para los inputs con los valores iniciales actualizados
  const [numeroDocumentosAnuales, setNumeroDocumentosAnuales] = useState(600);
  const [tiempoDedicado, setTiempoDedicado] = useState(35);
  const [numeroEmpleados, setNumeroEmpleados] = useState(14);
  const [pagoMedio, setPagoMedio] = useState(1400);
  //const [gastosExternos, setGastosExternos] = useState(0);

  // Constantes
  const porcentajeEficiencia = 0.3; // 15%

  // Calcular el ticket medio basado en condiciones específicas
  // Calcular el ticket medio basado en condiciones específicas
  const calcularTicketMedio = () => {
    if (numeroEmpleados >= 80) {
      return 4000; // Ticket medio máximo
    } else if (numeroEmpleados >= 50) {
      return 3000; // Ticket medio alto
    } else if (numeroEmpleados >= 20) {
      return 1500; // Ticket medio alto
    } else if (numeroEmpleados >= 10) {
      return 750; // Ticket medio
    } else {
      return 325; // Ticket medio base
    }
  };

  const nuestroTicketMedio = calcularTicketMedio();

  // Formato de números
  const formatNumber = (num) => {
    return num.toLocaleString("es-ES", { maximumFractionDigits: 2 });
  };

  useEffect(() => {
    if (onLoad) {
      onLoad();
    }
  }, [onLoad]);

  // Cálculos

  // Gastos actuales
  const gastosActuales =
    (tiempoDedicado / 100) * pagoMedio * numeroEmpleados * 12;

  // Inversión anual (costo de SafeCon por año)
  const inversion = nuestroTicketMedio * 12;

  // Nuevos gastos con SafeCon
  let nuevosGastos = gastosActuales * (1 - porcentajeEficiencia) + inversion;

  // Ahorro en gastos operativos
  let ahorroGastos = gastosActuales - nuevosGastos;

  // Evitar ahorro negativo
  if (ahorroGastos < 0) {
    ahorroGastos = 0;
  }

  // Beneficio es el ahorro en gastos
  let beneficio = ahorroGastos;

  // Calcular ROI anual corregido
  let roi = 0;
  if (inversion > 0) {
    roi = ((ahorroGastos - inversion) / inversion) * 100;
  }

  // Coste por documento antes y después
  let costoPorDocumentoAntes = gastosActuales / numeroDocumentosAnuales;
  let costoPorDocumentoDespues = nuevosGastos / numeroDocumentosAnuales;

  // Validación para evitar costos negativos o divisiones por cero
  if (numeroDocumentosAnuales <= 0) {
    costoPorDocumentoAntes = 0;
    costoPorDocumentoDespues = 0;
  } else {
    // Evitar costos negativos
    costoPorDocumentoAntes = Math.max(costoPorDocumentoAntes, 0);
    costoPorDocumentoDespues = Math.max(costoPorDocumentoDespues, 0);
  }

  // Datos para la gráfica
  const chartData = {
    labels: t("web.landing.calculator.labels", { returnObjects: true }),
    datasets: [
      {
        data: [gastosActuales, ahorroGastos],
        backgroundColor: ["#64b5f6", "#81e6d9"],
        hoverBackgroundColor: ["#42a5f5", "#4db6ac"],
        borderWidth: 0,
      },
    ],
  };

  // Opciones de la gráfica
  const chartOptions = {
    cutout: "70%",
    plugins: {
      legend: {
        display: false, // Deshabilitamos la leyenda predeterminada
      },
    },
  };

  // Marks para los sliders (mostrar límites)
  const createMarks = (min, max) => [
    { value: min, label: formatNumber(min) },
    { value: max, label: formatNumber(max) },
  ];

  return (
    <ThemeProvider theme={theme}>
      <div id="valuecalculator" className={styles.calculadoraContainer}>
        {/* Contenedor Principal */}
        <div className={styles.contenidoContainer}>
          {/* Tarjeta para Sliders */}
          <Card className={styles.cardSliders}>
            <CardContent>
              <div className={styles.tituloContainer}>
                <h1 className={styles.title}>
                  {t("web.landing.calculator.title")}
                </h1>
              </div>
              {/* Contenedor de Sliders */}
              <div className={styles.slidersContainer}>
                {/* Slider 1 */}
                <div className={styles.sliderItem}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    {t("web.landing.calculator.slider1")}:{" "}
                    {formatNumber(numeroDocumentosAnuales)}
                    <Tooltip title={t("web.landing.calculator.tooltip1")}>
                      <IconButton size="medium" style={{ marginLeft: 8 }}>
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <Slider
                    value={numeroDocumentosAnuales}
                    min={100}
                    max={10000}
                    step={100}
                    onChange={(e, val) => setNumeroDocumentosAnuales(val)}
                    valueLabelDisplay="auto"
                    marks={createMarks(100, 10000)}
                  />
                </div>
                {/* Slider 2 */}
                <div className={styles.sliderItem}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {t("web.landing.calculator.slider2")}: {tiempoDedicado}%
                    <Tooltip title={t("web.landing.calculator.tooltip2")}>
                      <IconButton size="medium" style={{ marginLeft: 8 }}>
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <Slider
                    value={tiempoDedicado}
                    min={25}
                    max={80}
                    step={5}
                    onChange={(e, val) => setTiempoDedicado(val)}
                    valueLabelDisplay="auto"
                    marks={createMarks(25, 80)}
                  />
                </div>
                {/* Slider 3 */}
                <div className={styles.sliderItem}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    {t("web.landing.calculator.slider3")}: {numeroEmpleados}
                    <Tooltip title={t("web.landing.calculator.tooltip3")}>
                      <IconButton size="medium" style={{ marginLeft: 8 }}>
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <Slider
                    value={numeroEmpleados}
                    min={10}
                    max={80}
                    step={1}
                    onChange={(e, val) => setNumeroEmpleados(val)}
                    valueLabelDisplay="auto"
                    marks={createMarks(10, 80)}
                  />
                </div>
                {/* Slider 4 */}
                <div className={styles.sliderItem}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    {t("web.landing.calculator.slider4")}:{" "}
                    {formatNumber(pagoMedio)} €/
                    {t("web.landing.calculator.month")}
                  </Typography>
                  <Slider
                    value={pagoMedio}
                    min={1000}
                    max={3000}
                    step={100}
                    onChange={(e, val) => setPagoMedio(val)}
                    valueLabelDisplay="auto"
                    marks={createMarks(100, 3000)}
                  />
                </div>
                <div className={styles.buttoncontainer}>
                  <Link to="/contact">
                    <button className={styles.ctaButton}>
                      {t("web.pricing.hero.ctaButton")}
                      <MoveRight />
                    </button>
                  </Link>
                </div>
                {/* Slider 5 
                <div className={styles.sliderItem}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    {t("web.landing.calculator.slider5")}:{" "}
                    {formatNumber(gastosExternos)} €/mes
                    <Tooltip title={t("web.landing.calculator.tooltip5")}>
                      <IconButton size="medium" style={{ marginLeft: 8 }}>
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <Slider
                    value={gastosExternos}
                    min={0}
                    max={5000}
                    step={100}
                    onChange={(e, val) => setGastosExternos(val)}
                    valueLabelDisplay="auto"
                    marks={createMarks(0, 5000)}
                  />
                </div>
                */}
              </div>
            </CardContent>
          </Card>
          {/* Contenedor de tarjetas a la derecha */}
          <div className={styles.rightContainer}>
            {/* Tarjeta de Coste por Documento */}
            <Card className={styles.cardCostos}>
              <CardContent>
                <Typography variant="h5" className={styles.cardTitle}>
                  {t("web.landing.calculator.titlecostperdocument")}
                </Typography>
                <div className={styles.resultadosContainer}>
                  <table className={styles.resultadosTable}>
                    <tbody>
                      <tr>
                        <td className={styles.resultadoLabel}>
                          <strong>{t("web.landing.calculator.cost1")}</strong>
                        </td>
                        <td
                          className={styles.resultadoValor}
                          style={{ color: "red" }}
                        >
                          {formatNumber(costoPorDocumentoAntes)} €
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.resultadoLabel}>
                          <strong>{t("web.landing.calculator.cost2")}</strong>
                        </td>
                        <td
                          className={styles.resultadoValor}
                          style={{ color: "green" }}
                        >
                          {formatNumber(costoPorDocumentoDespues)} €
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardContent>
            </Card>
            {/* Tarjeta de Gráfica */}
            <Card className={styles.cardGrafica}>
              <CardContent>
                <div className={styles.graficaContainer}>
                  {/* Gráfica */}
                  <div className={styles.chartContainer}>
                    <div className={styles.chartWrapper}>
                      <Doughnut data={chartData} options={chartOptions} />
                      {/* Contenido central */}
                      <div className={styles.centerContent}>
                        <div className={styles.label}>
                          <div className={styles.iconContainer}>
                            <div
                              className={styles.colorBox}
                              style={{
                                backgroundColor: "#81e6d9",
                              }}
                            ></div>
                            <TrendingUpIcon className={styles.icon} />
                          </div>
                          <div className={styles.text}>
                            <p>{t("web.landing.calculator.graph1")}</p>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "1.2em",
                                color: beneficio > 0 ? "green" : "black",
                              }}
                            >
                              {beneficio > 0
                                ? `+${formatNumber(beneficio)} €`
                                : t("web.landing.calculator.graph3")}
                            </p>
                            {/* Mostrar ROI */}
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "1em",
                                color: roi > 0 ? "green" : "black",
                              }}
                            >
                              ROI: {roi > 0 ? `${formatNumber(roi)}%` : "0%"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Leyenda Personalizada */}
                    <div className={styles.labelsContainer}>
                      <div className={styles.label}>
                        <div className={styles.iconContainer}>
                          <div
                            className={styles.colorBox}
                            style={{
                              backgroundColor: "#64b5f6",
                            }}
                          ></div>
                          <MonetizationOnIcon className={styles.icon} />
                        </div>
                        <div className={styles.text}>
                          <p>{t("web.landing.calculator.graph2")}</p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "1em",
                            }}
                          >
                            {formatNumber(gastosActuales)} €
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Calculator;
