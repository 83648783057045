// notificationSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      const newNotif = action.payload; // {id, title, type, description, timestamp, isNew, ...}
      // Evitar duplicados por timestamp+title, o si tienes un ID único:
      const exists = state.some(
        (notif) =>
          notif.timestamp === newNotif.timestamp &&
          notif.title === newNotif.title
      );
      if (!exists) {
        // Insertar al inicio (la más reciente)
        state.unshift(newNotif);
      }
    },
    removeNotification: (state, action) => {
      const idToRemove = action.payload;
      return state.filter((notif) => notif.id !== idToRemove);
    },
    clearNotifications: () => {
      return [];
    },
  },
});

export const { addNotification, removeNotification, clearNotifications } =
  notificationSlice.actions;

export default notificationSlice.reducer;
