import React from "react";
import ContentLoader from "react-content-loader";

const Fallback = (props) => (
  <ContentLoader viewBox="0 0 1000 240" height={275} width="100%" {...props}>
    {/* Títulos */}
    <rect x="3%" y="10" rx="3" ry="3" width="20%" height="10" />
    <rect x="93%" y="10" rx="3" ry="3" width="7%" height="10" />

    {/* Separador debajo de títulos */}
    <rect x="0" y="30" rx="0" ry="0" width="100%" height="1" />

    {/* Filas */}
    {[...Array(6)].map((_, index) => {
      const y = 40 + index * 60;
      return (
        <React.Fragment key={index}>
          <rect x="3%" y={y} rx="5" ry="5" width="50%" height="20" />
          <rect x="93%" y={y} rx="5" ry="5" width="3%" height="20" />
          {/* Separador */}
          <rect x="0" y={y + 30} rx="0" ry="0" width="100%" height="1" />
        </React.Fragment>
      );
    })}
  </ContentLoader>
);

export default Fallback;
