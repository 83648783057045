import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./SimplifiedProcess.module.css";
import processImage from "../../../../assets/images/MockupContraparte.webp";

const SimplifiedProcess = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>{t("web.simplifiedProcess.title")}</h2>
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: t("web.simplifiedProcess.description"),
          }}
        ></p>
        <ul className={styles.bulletList}>
          {t("web.simplifiedProcess.bullets", { returnObjects: true }).map(
            (bullet, index) => (
              <li key={index} className={styles.bulletItem}>
                <svg
                  className={styles.icon}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                <span>
                  <span
                    dangerouslySetInnerHTML={{ __html: bullet.title }}
                  ></span>{" "}
                  {bullet.description}
                </span>
              </li>
            )
          )}
        </ul>
      </div>
      <div className={styles.imageContainer}>
        <img
          src={processImage}
          alt={t("web.simplifiedProcess.imageAlt")}
          className={styles.image}
        />
      </div>
    </div>
  );
};

export default SimplifiedProcess;
