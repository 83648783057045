import React, { createContext, useContext, useEffect } from "react";
import { L10n } from "@syncfusion/ej2-base";
import syncfusionEN from "./languages/editor/editor-EN-US.json";
import syncfusionES from "./languages/editor/editor-ES.json";
import { useTranslation } from "react-i18next";

const SyncfusionContext = createContext();

export const SyncfusionProvider = ({ children }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Cargar las traducciones de Syncfusion según el idioma actual
    const syncfusionTranslations =
      i18n.language === "es" ? syncfusionES : syncfusionEN;
    L10n.load(syncfusionTranslations);
  }, [i18n.language]);

  return (
    <SyncfusionContext.Provider value={{}}>
      {children}
    </SyncfusionContext.Provider>
  );
};

export const useSyncfusionLocalization = () => {
  return useContext(SyncfusionContext);
};
