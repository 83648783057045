import { ChakraProvider, defaultSystem } from "@chakra-ui/react";
import { registerLicense } from "@syncfusion/ej2-base";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import App from "./App.js";
import i18n from "./i18n";
import { SyncfusionProvider } from "./SyncfusionProvider.js";

// Variable de entorno
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const FILEMANAGER_URI = `${API_BASE_URL}/graphql/FileManager/`;

// Variables globales para los tokens
// Estas variables almacenan los tokens de autenticación que serán usados en las solicitudes realizadas por el FileManager.
let authToken = "";
let idToken = "";

// Función para actualizar los tokens globales
// Esta función permite que cualquier componente del proyecto pueda actualizar los valores de authToken e idToken.
// Es utilizada específicamente en el componente FileManager para establecer los tokens obtenidos durante la autenticación.
export function setAuthTokens(newAuthToken, newIdToken) {
  authToken = newAuthToken;
  idToken = newIdToken;
}

// Interceptor de solicitudes XMLHttpRequest
// Este interceptor modifica todas las solicitudes realizadas por el FileManager al backend.
// Su propósito es agregar las cabeceras de autenticación (Authorization e X-ID-Token) a las solicitudes que coinciden con el endpoint del FileManager.
// Esto asegura que las rutas del backend estén protegidas y solo respondan a solicitudes autenticadas.
const originalXHR = XMLHttpRequest.prototype.open;

XMLHttpRequest.prototype.open = function (method, url, ...rest) {
  if (url.includes(FILEMANAGER_URI)) {
    this.addEventListener("readystatechange", function () {
      if (this.readyState === XMLHttpRequest.OPENED) {
        this.setRequestHeader("Authorization", `Bearer ${authToken}`);
        this.setRequestHeader("X-ID-Token", idToken);
      }
    });
  }
  originalXHR.call(this, method, url, ...rest);
};

// Listener para ignorar un warning inofensivo
// Este listener evita que un warning de "ResizeObserver loop limit exceeded" interrumpa el desarrollo.
// Es un problema conocido en algunos navegadores con ciertos estilos CSS.
// Este código no afecta la funcionalidad en producción.
window.addEventListener("error", (e) => {
  if (e.message && e.message.includes("ResizeObserver loop limit exceeded")) {
    e.stopImmediatePropagation();
  }
});

// index.js o App.js
window.addEventListener("error", (event) => {
  if (
    event.message.includes("Cannot read properties of null (reading 'length')")
  ) {
    console.warn("Syncfusion Splitter Error Captured:", event.message);
    event.stopImmediatePropagation();
  }
});

// **Registro de la licencia de Syncfusion**
// Este método registra la licencia para usar los componentes de Syncfusion, necesaria para evitar limitaciones en su funcionalidad.
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH1cdHRTRmldV0V0VkY="
);

// Configuración de la raíz de React
// Se monta la aplicación y se envuelven los principales contextos:
// - ChakraProvider: Proporciona estilos y componentes de diseño.
// - I18nextProvider: Proporciona soporte para traducción.
// - SyncfusionProvider: Proporciona configuraciones específicas para Syncfusion (Tambien de traducción).
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider value={defaultSystem}>
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <SyncfusionProvider>
          <App />
        </SyncfusionProvider>
      </I18nextProvider>
    </React.StrictMode>
  </ChakraProvider>
);
