import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Inject,
  Page,
  Selection,
} from "@syncfusion/ej2-react-grids";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FiDownload } from "react-icons/fi";
import Skeleton from "../../../assets/Animations/SkeletonAnexosStepperCliente";
import styles from "../Crear Contrato/AnexosModal.module.css";

const UPLOAD_ANEXO = gql`
  mutation UploadAnexo(
    $file: Upload!
    $documentKey: String!
    $encryptedPin: String!
    $company: String!
  ) {
    uploadAnexo(
      file: $file
      documentKey: $documentKey
      encryptedPin: $encryptedPin
      company: $company
    ) {
      success
      message
    }
  }
`;

const OBTENER_ANEXOS = gql`
  query ObtenerAnexos($documentKey: String!) {
    obtenerAnexos(documentKey: $documentKey) {
      titulo
      clave
    }
  }
`;

const DESCARGAR_ANEXO = gql`
  query DescargarAnexo(
    $anexoKey: String!
    $encryptedPin: String!
    $company: String!
  ) {
    descargarAnexo(
      anexoKey: $anexoKey
      encryptedPin: $encryptedPin
      company: $company
    ) {
      content
      message
    }
  }
`;

const Content2 = forwardRef(({ documentKey, encryptedPin, company }, ref) => {
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const fileInputRef = useRef(null);

  const [showMessage, setShowMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const { t, i18n } = useTranslation();

  const pageSettings = {
    pageSize: 5,
    pageSizes: [5, 10, 20, 50],
  };

  const {
    data: dataquery,
    loading: loadingquery,
    refetch,
  } = useQuery(OBTENER_ANEXOS, {
    variables: { documentKey },
    fetchPolicy: "network-only",
    onError: (error) => {
      console.error("Error fetching anexos:", error);
    },
  });

  useEffect(() => {
    if (dataquery && dataquery.obtenerAnexos) {
      const docs = dataquery.obtenerAnexos.map((doc) => ({
        name: doc.titulo,
        enlace: doc.enlace,
        id: doc.titulo,
        clave: doc.clave,
      }));

      const sortedDocs = docs.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
      );

      setDocuments(sortedDocs);
      setFilteredDocuments(sortedDocs);
    }
  }, [dataquery]);

  // BUSCADOR
  useEffect(() => {
    const term = searchTerm.toLowerCase().trim();
    if (term === "") {
      setFilteredDocuments(documents);
    } else {
      const filtered = documents.filter((doc) =>
        doc.name.toLowerCase().includes(term)
      );
      setFilteredDocuments(filtered);
    }
  }, [searchTerm, documents]);

  const [uploadAnexo, { loading: loadingUpload }] = useMutation(UPLOAD_ANEXO, {
    onCompleted: async (data) => {
      if (data.uploadAnexo.message === "DuplicateAnexo") {
        setAlertMessage(
          "Este documento ya existe. Si desea sobreescribirlo, elimínelo primero."
        );
        setAlertSeverity("warning");
        setShowMessage(true);
      } else if (data.uploadAnexo.success) {
        setAlertMessage(t("software.alerts.docs.successAlert"));
        setAlertSeverity("success");
        setShowMessage(true);
        // Espera a que el refetch devuelva los datos actualizados
        const { data: newData } = await refetch();
        if (newData && newData.obtenerAnexos) {
          const docs = newData.obtenerAnexos.map((doc) => ({
            name: doc.titulo,
            id: doc.titulo,
            clave: doc.clave,
          }));

          const sortedDocs = docs.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
          );

          setDocuments(sortedDocs);
          setFilteredDocuments(sortedDocs);
        }
      } else {
        setAlertMessage(t("software.alerts.docs.errorAlert"));
        setAlertSeverity("error");
        setShowMessage(true);
      }
    },
    onError: (error) => {
      console.error("Error uploading file:", error);
      setAlertMessage(t("software.alerts.docs.errorAlert"));
      setAlertSeverity("error");
      setShowMessage(true);
    },
  });

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      await uploadAnexo({
        variables: { file, documentKey, encryptedPin, company },
      });
      // Después de cargar el archivo, resetea el input:
      event.target.value = null;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const [descargarAnexo] = useLazyQuery(DESCARGAR_ANEXO);

  const downloadAnexo = async (anexoKey, titulo) => {
    try {
      const { data } = await descargarAnexo({
        variables: {
          anexoKey,
          encryptedPin, // Pasar encryptedPin
          company, // Pasar company
        },
      });

      if (data && data.descargarAnexo && data.descargarAnexo.content) {
        const base64Content = data.descargarAnexo.content;
        const byteCharacters = atob(base64Content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);
        const url = URL.createObjectURL(blob);

        // Crear un enlace temporal para descargar el archivo
        const a = document.createElement("a");
        a.href = url;
        a.download = titulo;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Liberar el objeto URL
        URL.revokeObjectURL(url);
      } else {
        console.error(
          "Error al descargar el anexo:",
          data.descargarAnexo.message
        );
      }
    } catch (error) {
      console.error("Error al ejecutar la consulta:", error);
    }
  };

  const downloadButtonTemplate = (props) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <IconButton onClick={() => downloadAnexo(props.clave, props.name)}>
        <FiDownload />
      </IconButton>
    </div>
  );

  // Exponer la función getAnexoData a través de la referencia
  useImperativeHandle(ref, () => ({
    getAnexoData: () => {
      // Retornar array con nombres de anexos
      const allDocuments = documents.map((doc) => doc.name);
      const uniqueDocuments = Array.from(new Set(allDocuments));
      return {
        anexos: uniqueDocuments,
      };
    },
  }));

  return (
    <Box
      sx={{
        mt: 3,
        mb: 2,
        height: "100%",
        maxHeight: "calc(100% - 64px)",
        overflow: "auto",
      }}
    >
      <Typography variant="h6" component="h2">
        {t("software.cliente.stepperModalCliente.content2.title")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => fileInputRef.current.click()}
            disabled={loadingUpload}
            sx={{ textTransform: "none" }}
            className={styles.añadiranexoboton}
          >
            {loadingUpload ? (
              <CircularProgress size={22} color="inherit" />
            ) : (
              `${t("software.attached.add")}`
            )}
          </Button>
          {showMessage && (
            <Alert
              onClose={() => setShowMessage(false)}
              severity={alertSeverity}
              sx={{
                ml: 2,
                minWidth: "200px",
                height: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {alertMessage}
            </Alert>
          )}
        </Box>
        <TextField
          variant="outlined"
          size="small"
          placeholder={t("software.attached.searchAttached")}
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          sx={{
            ml: 2,
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#61d1b5",
              },
            },
          }}
        />
      </Box>
      <input
        type="file"
        onChange={handleFileUpload}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      <Box sx={{ mt: 3, mb: 2 }}>
        {loadingquery ? (
          <Skeleton />
        ) : (
          <GridComponent
            locale={i18n.language}
            dataSource={filteredDocuments}
            allowPaging={true}
            pageSettings={pageSettings}
            height="37vh"
          >
            <ColumnsDirective>
              <ColumnDirective
                field="name"
                headerText={t("software.attached.title")}
                width="85"
              />
              <ColumnDirective
                headerText={t("software.attached.downloadTitle")}
                template={downloadButtonTemplate}
                width="15"
              />
            </ColumnsDirective>
            <Inject services={[Selection, Page, Filter]} />
          </GridComponent>
        )}
      </Box>
    </Box>
  );
});

export default Content2;
