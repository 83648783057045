import React from "react";
import { ExternalLink, DollarSign, Building2, Cloud } from "lucide-react";
import { useTranslation } from "react-i18next";
import styles from "./Problems.module.css";

const Problems = () => {
  const { t } = useTranslation();

  const stats = [
    {
      icon: <DollarSign className={styles.icon} />,
      number: t("web.problems.stats.0.number"),
      description: t("web.problems.stats.0.description"),
    },
    {
      icon: <Building2 className={styles.icon} />,
      number: t("web.problems.stats.1.number"),
      description: t("web.problems.stats.1.description"),
    },
    {
      icon: <Cloud className={styles.icon} />,
      number: t("web.problems.stats.2.number"),
      description: t("web.problems.stats.2.description"),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t("web.problems.title")}</h2>
        <p className={styles.description}>{t("web.problems.description")}</p>
      </div>

      <div className={styles.statsGrid}>
        {stats.map((stat, index) => (
          <div key={index} className={styles.card}>
            <div className={styles.cardHeader}>
              {stat.icon}
              <div className={styles.number}>{stat.number}</div>
            </div>
            <div className={styles.cardContent}>
              <p className={styles.cardDescription}>{stat.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.footer}>
        <a
          href={t("web.problems.footer.link")}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          <span>{t("web.problems.footer.text")}</span>
          <ExternalLink className={styles.linkIcon} />
        </a>
      </div>
    </div>
  );
};

export default Problems;
