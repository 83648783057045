import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Inject,
  Page,
  Selection,
} from "@syncfusion/ej2-react-grids";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiDownload } from "react-icons/fi";
import Skeleton from "../../../assets/Animations/SkeletonAnexosCliente";
import styles from "../Crear Contrato/AnexosModal.module.css";

const UPLOAD_ANEXO = gql`
  mutation UploadAnexo(
    $file: Upload!
    $documentKey: String!
    $encryptedPin: String!
    $company: String!
  ) {
    uploadAnexo(
      file: $file
      documentKey: $documentKey
      encryptedPin: $encryptedPin
      company: $company
    ) {
      success
      message
    }
  }
`;

const OBTENER_ANEXOS = gql`
  query ObtenerAnexos($documentKey: String!) {
    obtenerAnexos(documentKey: $documentKey) {
      titulo
      clave
    }
  }
`;

const DOWNLOAD_ANEXO = gql`
  mutation DownloadAnexo(
    $anexoKey: String!
    $encryptedPin: String!
    $company: String!
    $userEmail: String!
    $documentKey: String!
  ) {
    downloadAnexo(
      anexoKey: $anexoKey
      encryptedPin: $encryptedPin
      company: $company
      userEmail: $userEmail
      documentKey: $documentKey
    ) {
      status {
        success
        message
        content
      }
    }
  }
`;


export default function AnexosModal({
  onClose,
  documentKey,
  onAnexosCount,
  encryptedPin,
  company,
  userEmail,
}) {
  const { t, i18n } = useTranslation();
  const fileInputRef = useRef(null);

  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const pageSettings = {
    pageSize: 3,
    pageSizes: [3, 5, 10, 20],
  };

  const {
    data: dataquery,
    loading: loadingquery,
    refetch,
  } = useQuery(OBTENER_ANEXOS, {
    variables: { documentKey },
    fetchPolicy: "network-only",
    onError: (error) => {
      console.error("Error fetching anexos:", error);
    },
  });

  useEffect(() => {
    if (dataquery && dataquery.obtenerAnexos) {
      const docs = dataquery.obtenerAnexos.map((doc) => ({
        name: doc.titulo,
        enlace: doc.enlace,
        id: doc.titulo,
        clave: doc.clave,
      }));

      const sortedDocs = docs.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
      );

      setDocuments(sortedDocs);
      setFilteredDocuments(sortedDocs);
    }
  }, [dataquery]);

  // BUSCADOR
  useEffect(() => {
    const term = searchTerm.toLowerCase().trim();
    if (term === "") {
      setFilteredDocuments(documents);
    } else {
      const filtered = documents.filter((doc) =>
        doc.name.toLowerCase().includes(term)
      );
      setFilteredDocuments(filtered);
    }
  }, [searchTerm, documents]);

  //Actualizar numero de anexos
  useEffect(() => {
    onAnexosCount(documents.length);
  }, [documents, onAnexosCount]);

  const [uploadAnexo, { loading: loadingUpload }] = useMutation(UPLOAD_ANEXO, {
    onCompleted: async (data) => {
      if (data.uploadAnexo.message === "DuplicateAnexo") {
        setAlertMessage(
          "Este documento ya existe. Si desea sobreescribirlo, elimínelo primero."
        );
        setAlertSeverity("warning");
        setShowMessage(true);
      } else if (data.uploadAnexo.success) {
        setAlertMessage(t("software.alerts.docs.successAlert"));
        setAlertSeverity("success");
        setShowMessage(true);
        // Espera a que el refetch devuelva los datos actualizados
        const { data: newData } = await refetch();
        if (newData && newData.obtenerAnexos) {
          const docs = newData.obtenerAnexos.map((doc) => ({
            name: doc.titulo,
            id: doc.titulo,
            clave: doc.clave,
          }));

          const sortedDocs = docs.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
          );

          setDocuments(sortedDocs);
          setFilteredDocuments(sortedDocs);
        }
      } else {
        setAlertMessage(t("software.alerts.docs.errorAlert"));
        setAlertSeverity("error");
        setShowMessage(true);
      }
    },
    onError: (error) => {
      console.error("Error uploading file:", error);
      setAlertMessage(t("software.alerts.docs.errorAlert"));
      setAlertSeverity("error");
      setShowMessage(true);
    },
  });

  const [downloadAnexo] = useMutation(DOWNLOAD_ANEXO);


  const downloadAnexoFunc = async (anexoKey, titulo) => {
    try {
      const { data } = await downloadAnexo({
        variables: {
          anexoKey,
          encryptedPin,
          company,
          userEmail: userEmail, // Obtener el email del usuario autenticado
          documentKey,
        },
      });
  
      if (
        data &&
        data.downloadAnexo &&
        data.downloadAnexo.status &&
        data.downloadAnexo.status.success
      ) {
        const base64Content = data.downloadAnexo.status.content;
        const byteCharacters = atob(base64Content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);
        const url = URL.createObjectURL(blob);
  
        // Crear un enlace temporal para descargar el archivo
        const a = document.createElement("a");
        a.href = url;
        a.download = titulo;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
  
        // Liberar el objeto URL
        URL.revokeObjectURL(url);
      } else {
        console.error(
          "Error al descargar el anexo:",
          data?.downloadAnexo?.status?.message
        );
      }
    } catch (error) {
      console.error("Error al ejecutar la mutación:", error);
    }
  };
  

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      await uploadAnexo({
        variables: { file, documentKey, encryptedPin, company },
      });
      // Después de cargar el archivo, resetea el input:
      event.target.value = null;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const downloadButtonTemplate = (props) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <IconButton onClick={() => downloadAnexoFunc(props.clave, props.name)}>
        <FiDownload />
      </IconButton>
    </div>
  );

  return (
    <Modal open={true} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "70%", md: "50%" },
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 10 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          {t("software.attached.add")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            mb: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => fileInputRef.current.click()}
            sx={{ textTransform: "none" }}
            className={styles.añadiranexoboton}
            disabled={loadingUpload}
          >
            {loadingUpload ? (
              <CircularProgress size={22} color="inherit" />
            ) : (
              `${t("software.attached.add")}`
            )}
          </Button>
          <TextField
            variant="outlined"
            size="small"
            placeholder={t("software.attached.searchAttached")}
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            sx={{
              ml: 2,
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.23)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#61d1b5",
                },
              },
            }}
          />
        </Box>
        <input
          type="file"
          onChange={handleFileUpload}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <Box sx={{ mt: 3, mb: 2 }}>
          {loadingquery ? (
            <Skeleton />
          ) : (
            <GridComponent
              key={filteredDocuments.map((d) => d.id).join(",")}
              locale={i18n.language}
              dataSource={[...filteredDocuments]}
              allowPaging={true}
              pageSettings={pageSettings}
              height="200px"
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="name"
                  headerText={t("software.attached.title")}
                  width="85"
                />
                <ColumnDirective
                  headerText={t("software.attached.downloadTitle")}
                  template={downloadButtonTemplate}
                  width="15"
                />
              </ColumnsDirective>
              <Inject services={[Selection, Page, Filter]} />
            </GridComponent>
          )}
        </Box>
        {showMessage && (
          <Alert
            onClose={() => setShowMessage(false)}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        )}
      </Box>
    </Modal>
  );
}
