import React, { useRef, useEffect, useState } from "react";
import styles from "./Banner.module.css";

// Importar las imágenes como variables de JavaScript
import logo1 from "../../../assets/images/lanzadera.webp";
import logo2 from "../../../assets/images/AbogadoAmigo.svg";
import logo3 from "../../../assets/images/ejaso.png";
import logo4 from "../../../assets/images/GRA.png";
import logo5 from "../../../assets/images/logDriveo.png";
import logo6 from "../../../assets/images/LogoShadow.png";
import logo7 from "../../../assets/images/Logo_for_Google_for_Startups_page.png";
import logo8 from "../../../assets/images/Valistiq.webp";
const logos = [
  { src: logo1, width: 100 },
  { src: logo2, width: 100 },
  { src: logo3, width: 100 },
  { src: logo4, width: 70 },
  { src: logo5, width: 100 },
  { src: logo6, width: 100 },
  { src: logo7, width: 100 },
  { src: logo8, width: 100 },
];

export default function Banner() {
  const scrollRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    const totalWidth = scrollElement.scrollWidth / 2;

    const scroll = () => {
      setScrollPosition((prevPosition) => {
        const newPosition = (prevPosition + 0.5) % totalWidth;
        return newPosition;
      });
    };

    const intervalId = setInterval(scroll, 10);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={styles.wrapper}>
      <h1>Confian en nosotros</h1>
      <div className={styles.bannerContainer}>
        <div className={styles.fadeLeft}></div>
        <div className={styles.fadeRight}></div>
        <div
          className={styles.carouselTrack}
          ref={scrollRef}
          style={{ transform: `translateX(-${scrollPosition}px)` }}
        >
          {[...logos, ...logos].map((logo, index) => (
            <div key={index} className={styles.logo}>
              <img
                src={logo.src}
                alt={`Logo ${index + 1}`}
                width={logo.width}
                height={logo.height}
                className={styles.logoImage}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
