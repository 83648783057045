import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./HomeHeader.module.css";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../../assets/Animations/SafeCon.json";
import Banner from "./Banner";
import { gsap } from "gsap";

export default function HomeHeader() {
  const { t } = useTranslation();
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [showLottie, setShowLottie] = useState(window.innerWidth > 768);

  const words = t("web.landing.homeHeader.rotatingWords", {
    returnObjects: true,
  });

  const wordWrapperRef = useRef(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const handleResize = () => setShowLottie(window.innerWidth > 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const animateWords = () => {
      const tl = gsap.timeline();

      tl.to(wordWrapperRef.current, {
        y: "-100%",
        opacity: 0,
        duration: 0.8,
        ease: "power2.inOut",
        onComplete: () => {
          // Cambiar palabra mientras está oculta
          setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        },
      })
        .set(wordWrapperRef.current, { y: "100%" })
        .to(wordWrapperRef.current, {
          y: "0%",
          opacity: 1,
          duration: 0.8,
          ease: "power2.inOut",
        });
    };

    const interval = setInterval(animateWords, 3200); // Tiempo total: 0.8s salida + 0.8s entrada + 1.6s pausa
    animateWords(); // Llama a la animación inicial

    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <>
      <div className={styles.fill}>
        <div className={styles.fondoContainer}>
          <div className={styles.textoContainer}>
            <ul className={styles.descripcionEmpresa}>
              <div className={styles.titleheader}>
                <h1 className={styles.nombreEmpresa}>
                  {t("web.landing.homeHeader.companyName")}
                </h1>

                <div className={styles.cambiandoPalabras}>
                  <div ref={wordWrapperRef} className={styles.wordWrapper}>
                    {words[currentWordIndex]}
                  </div>
                </div>
              </div>
              <p className={styles.description}>
                {t("web.landing.homeHeader.description")}
              </p>
              <Link
                to="https://calendly.com/gerardo-safecon/conoce-safecon-con-nuestro-ceo"
                className={styles.buttonFee}
              >
                {t("web.landing.homeHeader.requestDemo")}
              </Link>
            </ul>
          </div>
          {showLottie && (
            <div className={styles.imagenContainer}>
              <Lottie
                isClickToPauseDisabled={true}
                options={defaultOptions}
                width={"100%"}
              />
            </div>
          )}
        </div>
      </div>
      <Banner />
    </>
  );
}
