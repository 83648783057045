import React, { useEffect } from "react";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/styles/App.css";
import HomeRoutes from "./Routes/Home-routes";
import store, { persistor } from "./store"; // Asegúrate de importar el persistor

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { fetchAuthSession } from "@aws-amplify/auth";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { Amplify } from "aws-amplify";

const AWS_PROJECT_REGION = process.env.REACT_APP_AWS_PROJECT_REGION;
const AWS_COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION;
const AWS_USER_POOLS_ID = process.env.REACT_APP_AWS_USER_POOLS_ID;
const USER_POOLS_WEB_CLIENT_ID =
  process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID;

const config = {
  aws_project_region: `${AWS_PROJECT_REGION}`,
  aws_cognito_region: `${AWS_COGNITO_REGION}`,
  aws_user_pools_id: `${AWS_USER_POOLS_ID}`,
  aws_user_pools_web_client_id: `${USER_POOLS_WEB_CLIENT_ID}`,
};

Amplify.configure(config);

// Base URL de tu API desde la variable de entorno
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// URL completa de GraphQL
const GRAPHQL_URI = `${API_BASE_URL}/graphql`;

const uploadLink = createUploadLink({
  uri: GRAPHQL_URI, // Usa tu propio endpoint aquí
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const session = await fetchAuthSession(); // Fetch the authentication session
    const accessToken = session.tokens.accessToken.toString();
    const idToken = session.tokens.idToken.toString();
    //console.log("Access Token:", session.tokens.accessToken.toString());
    //console.log("ID Token:", session.tokens.idToken.toString());
    // Agrega ambos tokens en los headers si necesitas enviar ambos
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
        "X-ID-Token": idToken,
      },
    };
  } catch (error) {
    console.error("Error getting the auth tokens", error);
    return { headers }; // Continúa sin los tokens si ocurre un error
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${JSON.stringify(path)}`
      );

      // Manejar el error 429
      if (message.includes("Too Many Requests")) {
        alert(
          "Has excedido el límite de solicitudes. Por favor, espera un momento."
        );
        // Aquí podrías implementar una lógica adicional para deshabilitar botones o bloquear más acciones
        return;
      }
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    if (networkError.statusCode === 429) {
      alert("Demasiadas solicitudes. Intenta de nuevo más tarde.");
      return;
    }
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, uploadLink]), // Encadena authLink con httpLink
  cache: new InMemoryCache(),
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // O 'instant' en navegadores que lo soporten.
    });
  }, [pathname]); // Dependencia actualizada a pathname para mejor claridad

  return null;
}

const ScrollToTopOnRefresh = () => {
  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual";
  }

  return null;
};

// Definición del enrutador usando `createBrowserRouter`
const router = createBrowserRouter([
  {
    path: "/*",
    element: <HomeRoutes />,
  },
]);

export default function App() {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ScrollToTopOnRefresh />
          <RouterProvider router={router}>
            <ScrollToTop />
          </RouterProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}
