import React from "react";
import styles from "./Privacy.module.css"; // Importamos el CSS modular
import { useTranslation, Trans } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>{t("web.policies.privacy.title")}</h1>
      <p className={styles.lastUpdated}>
        {t("web.policies.privacy.lastUpdated")}
      </p>

      {/* 1. Introducción */}
      <section>
        <h2 className={`${styles.subheader} ${styles.bold}`}>
          {t("web.policies.privacy.introduction.header")}
        </h2>
        <p>{t("web.policies.privacy.introduction.description")}</p>
      </section>

      {/* 2. Responsable del Tratamiento */}
      <section>
        <h2 className={`${styles.subheader} ${styles.bold}`}>
          {t("web.policies.privacy.dataController.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.dataController.description" />
        </p>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            {t("web.policies.privacy.dataController.details.companyName")}
          </li>
          <li className={styles.listItem}>
            {t("web.policies.privacy.dataController.details.address")}
          </li>
          <li className={styles.listItem}>
            <a href="mailto:support@safecon.app">
              {t("web.policies.privacy.dataController.details.contact")}
            </a>
          </li>
        </ul>
      </section>

      {/* 3. Datos Personales que Recopilamos y Tratamientos Realizados */}
      <section>
        <h2 className={`${styles.subheader} ${styles.bold}`}>
          {t("web.policies.privacy.dataCollection.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.dataCollection.description" />
        </p>

        {/* Datos de Facturación y Registro de la Empresa */}
        <h3 className={styles.subsectionTitle}>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.billingAndCompanyRegistrationData.title" />
        </h3>
        <p>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.billingAndCompanyRegistrationData.description" />
        </p>
        <ul className={styles.list}>
          {t(
            "web.policies.privacy.dataCollection.sections.billingAndCompanyRegistrationData.items",
            { returnObjects: true }
          ).map((item, index) => (
            <li className={styles.listItem} key={index}>
              {item}
            </li>
          ))}
        </ul>
        <p className={styles.legalBasesTitle}>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.billingAndCompanyRegistrationData.legalBasesTitle" />
        </p>
        <ul className={styles.list}>
          {t(
            "web.policies.privacy.dataCollection.sections.billingAndCompanyRegistrationData.legalBases",
            { returnObjects: true }
          ).map((item, index) => (
            <li className={styles.listItem} key={index}>
              {item}
            </li>
          ))}
        </ul>

        {/* Datos de los Usuarios de la Plataforma */}
        <h3 className={styles.subsectionTitle}>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.platformUserData.title" />
        </h3>
        <p>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.platformUserData.description" />
        </p>
        <ul className={styles.list}>
          {t(
            "web.policies.privacy.dataCollection.sections.platformUserData.items",
            { returnObjects: true }
          ).map((item, index) => (
            <li className={styles.listItem} key={index}>
              {item}
            </li>
          ))}
        </ul>
        <p>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.platformUserData.note" />
        </p>
        <p className={styles.legalBasesTitle}>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.platformUserData.legalBasesTitle" />
        </p>
        <ul className={styles.list}>
          {t(
            "web.policies.privacy.dataCollection.sections.platformUserData.legalBases",
            { returnObjects: true }
          ).map((item, index) => (
            <li className={styles.listItem} key={index}>
              {item}
            </li>
          ))}
        </ul>

        {/* Datos de Uso y Navegación */}
        <h3 className={styles.subsectionTitle}>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.usageData.title" />
        </h3>
        <p>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.usageData.description" />
        </p>
        <ul className={styles.list}>
          {t("web.policies.privacy.dataCollection.sections.usageData.items", {
            returnObjects: true,
          }).map((item, index) => (
            <li className={styles.listItem} key={index}>
              {item}
            </li>
          ))}
        </ul>
        <p className={styles.legalBasesTitle}>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.usageData.legalBasesTitle" />
        </p>
        <ul className={styles.list}>
          {t(
            "web.policies.privacy.dataCollection.sections.usageData.legalBases",
            { returnObjects: true }
          ).map((item, index) => (
            <li className={styles.listItem} key={index}>
              {item}
            </li>
          ))}
        </ul>

        {/* Datos Documentales y Contenidos Confidenciales */}
        <h3 className={styles.subsectionTitle}>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.documentData.title" />
        </h3>
        <p>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.documentData.description" />
        </p>
        <ul className={styles.list}>
          {t(
            "web.policies.privacy.dataCollection.sections.documentData.items",
            { returnObjects: true }
          ).map((item, index) => (
            <li className={styles.listItem} key={index}>
              {item}
            </li>
          ))}
        </ul>
        <p>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.documentData.encryptionNote" />
        </p>
        <p className={styles.legalBasesTitle}>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.documentData.legalBasesTitle" />
        </p>
        <ul className={styles.list}>
          {t(
            "web.policies.privacy.dataCollection.sections.documentData.legalBases",
            { returnObjects: true }
          ).map((item, index) => (
            <li className={styles.listItem} key={index}>
              {item}
            </li>
          ))}
        </ul>

        {/* Datos para Soporte y Comunicación */}
        <h3 className={styles.subsectionTitle}>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.supportData.title" />
        </h3>
        <p>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.supportData.description" />
        </p>
        <ul className={styles.list}>
          {t("web.policies.privacy.dataCollection.sections.supportData.items", {
            returnObjects: true,
          }).map((item, index) => (
            <li className={styles.listItem} key={index}>
              {item}
            </li>
          ))}
        </ul>
        <p className={styles.legalBasesTitle}>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.supportData.legalBasesTitle" />
        </p>
        <ul className={styles.list}>
          {t(
            "web.policies.privacy.dataCollection.sections.supportData.legalBases",
            { returnObjects: true }
          ).map((item, index) => (
            <li className={styles.listItem} key={index}>
              {item}
            </li>
          ))}
        </ul>

        {/* Datos para Comunicaciones Comerciales */}
        <h3 className={styles.subsectionTitle}>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.commercialData.title" />
        </h3>
        <p>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.commercialData.description" />
        </p>
        <p className={styles.legalBasesTitle}>
          <Trans i18nKey="web.policies.privacy.dataCollection.sections.commercialData.legalBasesTitle" />
        </p>
        <ul className={styles.list}>
          {t(
            "web.policies.privacy.dataCollection.sections.commercialData.legalBases",
            { returnObjects: true }
          ).map((item, index) => (
            <li className={styles.listItem} key={index}>
              {item}
            </li>
          ))}
        </ul>
      </section>

      {/* 4. Finalidades del Tratamiento de los Datos */}
      <section>
        <h2 className={`${styles.subheader} ${styles.bold}`}>
          {t("web.policies.privacy.dataPurpose.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.dataPurpose.description" />
        </p>
        <ul className={styles.list}>
          {t("web.policies.privacy.dataPurpose.purposes", {
            returnObjects: true,
          }).map((purpose, index) => (
            <li className={styles.listItem} key={index}>
              {purpose}
            </li>
          ))}
        </ul>
      </section>

      {/* 5. Compartición de la Información */}
      <section>
        <h2 className={`${styles.subheader} ${styles.bold}`}>
          {t("web.policies.privacy.dataSharing.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.dataSharing.description" />
        </p>
        <div>
          {t("web.policies.privacy.dataSharing.sharingWith", {
            returnObjects: true,
          }).map((sharingCategory, index) => (
            <div key={index}>
              <h3 className={styles.subsectionTitle}>
                {sharingCategory.title}
              </h3>
              <p>{sharingCategory.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* 6. Transferencias Internacionales de Datos */}
      <section>
        <h2 className={`${styles.subheader} ${styles.bold}`}>
          {t("web.policies.privacy.dataTransfers.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.dataTransfers.description" />
        </p>
      </section>

      {/* 7. Medidas de Seguridad */}
      <section>
        <h2 className={`${styles.subheader} ${styles.bold}`}>
          {t("web.policies.privacy.securityMeasures.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.securityMeasures.description" />
        </p>
        <div>
          {t("web.policies.privacy.securityMeasures.measures", {
            returnObjects: true,
          }).map((measure, index) => (
            <div key={index}>
              <h3 className={styles.subsectionTitle}>{measure.title}</h3>
              <p>{measure.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* 8. Conservación de los Datos */}
      <section>
        <h2 className={`${styles.subheader} ${styles.bold}`}>
          {t("web.policies.privacy.dataRetention.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.dataRetention.description" />
        </p>
      </section>

      {/* 9. Derechos del Interesado */}
      <section>
        <h2 className={`${styles.subheader} ${styles.bold}`}>
          {t("web.policies.privacy.dataSubjectRights.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.dataSubjectRights.description" />
        </p>
        <ul className={styles.list}>
          {t("web.policies.privacy.dataSubjectRights.rights", {
            returnObjects: true,
          }).map((right, index) => (
            <li className={styles.listItem} key={index}>
              <strong>{right.title}:</strong> {right.description}
            </li>
          ))}
        </ul>
        <p>
          {t("web.policies.privacy.dataSubjectRights.contact")}{" "}
          <a
            href={`mailto:${t("web.policies.privacy.dataSubjectRights.email")}`}
          >
            {t("web.policies.privacy.dataSubjectRights.email")}
          </a>
        </p>
        <p>
          <Trans i18nKey="web.policies.privacy.dataSubjectRights.responseTime" />
        </p>
      </section>
      {/* 10 y 11 */}
      <section>
        {/* Punto 10: Cambios en la Política de Privacidad */}
        <h2 className={`${styles.subheader} ${styles.bold}`}>
          {t("web.policies.privacy.privacyPolicyChanges.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.privacyPolicyChanges.description" />
        </p>

        {/* Punto 11: Contacto */}
        <h2 className={`${styles.subheader} ${styles.bold}`}>
          {t("web.policies.privacy.contact.header")}
        </h2>
        <p>
          {t("web.policies.privacy.contact.description")}{" "}
          <a href={`mailto:${t("web.policies.privacy.contact.email")}`}>
            {t("web.policies.privacy.contact.email")}
          </a>
        </p>
      </section>

      {/* 12 y 13 */}
      <section>
        {/* Punto 12: Reclamaciones Ante la Autoridad de Control */}
        <h2 className={`${styles.subheader} ${styles.bold}`}>
          {t("web.policies.privacy.complaints.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.complaints.description" />{" "}
          <a
            href={t("web.policies.privacy.complaints.url")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("web.policies.privacy.complaints.url")}
          </a>
        </p>

        {/* Punto 13: Aceptación de la Política */}
        <h2 className={`${styles.subheader} ${styles.bold}`}>
          {t("web.policies.privacy.acceptance.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.acceptance.description" />
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
